import {Component} from 'react';
import LDH from '../helpers/LeopardDataHelper';

class LeopardCognitoConfig extends Component {
    // -----------------------------------------------------------------------------
    // --------------------- ST2 Testing Configuration -----------------------------
    // -----------------------------------------------------------------------------
    static AmplifyAuthAndAPIConfig = {
        Auth: {
            identityPoolId: "ap-southeast-2:a7a2785b-33fd-45f7-8874-783578351d67",
            region: "ap-southeast-2",
            userPoolId: "ap-southeast-2_6BYSVhYQn",
            userPoolWebClientId: "35ktcuv7em25qr56avr4lb2kiv"
        },
        API: {
            endpoints: [
                {
                    name: "aws-api-gateway",
                    endpoint: LDH.APIEndpointAdapter(),
                    region: "ap-southeast-2"
                }
            ]
        }
    };

    // -----------------------------------------------------------------------------
    // ------------------------ Dev4 Configuration ---------------------------------
    // -----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:e6f6a75a-1ca5-4517-acbf-bddaa5e3a718",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_AMRoro1w1",
    //         userPoolWebClientId: "3r6k2i66nu633hhe5iif7sqoi7"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: LDH.APIEndpointAdapter(),
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };

    // -----------------------------------------------------------------------------
    // ------------------------ Dev5 Configuration ---------------------------------
    // -----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:76f86bd3-5b36-4167-9ccd-e7efd66e3528",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_nnjhMAY25",
    //         userPoolWebClientId: "76avvb2c89ea6lgrnq6p8thddr"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: LDH.APIEndpointAdapter(),
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };

    // -----------------------------------------------------------------------------
    // ------------------------- Dev7 Configuration --------------------------------
    // -----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:61c629eb-1656-4068-8564-7ae7262705fb",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_C1uYHS6FM",
    //         userPoolWebClientId: "45bs4fa0l88rjv3he5mfgv0krp"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: LDH.APIEndpointAdapter(),
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };

    // ----------------------------------------------------------------------------
    // ------------------------- Demo Configuration -------------------------------
    // ----------------------------------------------------------------------------
    // static AmplifyAuthAndAPIConfig = {
    //     Auth: {
    //         identityPoolId: "ap-southeast-2:efea3b98-43c8-46dd-97b4-f8fb2286034e",
    //         region: "ap-southeast-2",
    //         userPoolId: "ap-southeast-2_B3DbbfD0B",
    //         userPoolWebClientId: "27dg1nlemf5mj83481bnqfjapt"
    //     },
    //     API: {
    //         endpoints: [
    //             {
    //                 name: "aws-api-gateway",
    //                 endpoint: LDH.APIEndpointAdapter(),
    //                 region: "ap-southeast-2"
    //             }
    //         ]
    //     }
    // };
}

export default LeopardCognitoConfig;
